export default {

    EMPLOYEE_TITLES: [
        [216, 'Applied Designer Drafter'],
        [217, 'Applied Inside Sales Eng'],
        [227, 'Administrative Support Specialist'],
        [251, 'Batching Tech'],
        [58, 'Billing/AR/CC Specialist'],
        [250, 'Continuous Improvement Manager'],
        [325, 'Controller'],
        [73, 'Crater'],
        [82, 'Design Engineer'],
        [84, 'Designer Drafter'],
        [218, 'Design/Mrkting Specialist'],
        [326, 'Director of Production'],
        [83, 'Drafter'],
        [80, 'Engineering Manager'],
        [81, 'Engineer'],
        [85, 'Floater'],
        [247, 'HR Generalist'],
        [246, 'HR Manager'],
        [60, 'Human Resources'],
        [230, 'Human Resources/Administrative Assistant'],
        [53, 'Integrator/COO'],
        [74, 'Insulator'],
        [68, 'Insulating/Crating Lead'],
        [252, 'Inside Estimator'],
        [55, 'Inside Sales'],
        [56, 'Inside Sales Lead'],
        [79, 'Iso Tech'],
        [376, 'IT Help Desk'],
        [87, 'IT Infrastructure Admin'],
        [61, 'Logistics Manager'],
        [62, 'Logistics Support'],
        [229, 'Maintenance Technician'],
        [86, 'Material Handler'],
        [249, 'Material Manager'],
        [248, 'Measure Tech'],
        [219, 'New Prod Dev Eng'],
        [54, 'Outside Sales'],
        [71, 'Open'],
        [64, 'Production Supervisor'],
        [65, 'Plant Manager'],
        [66, 'Production Ops Manager'],
        [69, 'Purchasing/Facilities Manager'],
        [77, 'Quality Manager'],
        [78, 'QC Tech'],
        [57, 'Sales Support'],
        [324, 'Senior Recruiter'],
        [59, 'Staff Accountant'],
        [70, 'Turret Lead'],
        [76, 'Turret Operator'],
        [52, 'Visionary /CEO'],
        [63, 'VP of Finance'],
        [244, 'VP of Operations'],
        [245, 'VP of Sales'],
        [67, 'Welder Lead'],
        [72, 'Welder'],
        [75, 'Web/IT Manager'],
    ],

    USER_TYPES: [
        [1, 'Customer'],
        [3, 'Vendor'],
        [4, 'Employee'],
        [5, 'New User'],
        [6, 'Warehouse'],
        [7, 'Shipper'],
        [8, 'API'],
        [9, 'Prospect']
    ],

    USER_SPECIAL_TAGS: [
        [94, 'National Accounts'],
        [619, 'Lennox CRDC']
    ],
    
    RECOGNITION_VALUES: [
        [221, 'Speed'],
        [222, 'Customer Service'],
        [223, 'Teamwork'],
        [224, 'Honesty'],
        [225, 'Continuous Improvement'],
        [371, 'Fast'],
        [372, 'Easy'],
        [373, 'Fun']
    ],
    
    DEPARTMENTS: [
        [327, 'Sales'],
        [328, 'Engineering'],
        [329, 'Admin'],
        [330, 'Welders'],
        [331, 'Turret'],
        [332, 'Insulation/Crating'],
        [333, 'Logistics'],
        [334, 'Leaders']
    ],
    
    AUTO_DRAWING_TEAMS: [
        [377, 'Engineering'],
        [378, 'Sales Support'],
        [379, 'Regional Sales'],
        [380, 'Measure']
    ]
}